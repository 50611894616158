.section-form {
    padding-top: 7rem !important;
    padding-bottom: 2rem !important;
}

@media screen and (width > 991px) {
    .section-form {
        padding-top: 10rem !important;
        padding-bottom: 10rem !important;
    }
}

